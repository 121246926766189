import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth'; 
import './App.css';
import './Main.css';

import Menu from './pages/Menu';
import Plan from './pages/Plan';
import PointReading from './pages/PointReading';
import PointUsing from './pages/PointUsing';
import CardIssuance from './pages/CardIssuance';
import ShopInfo from './pages/ShopInfo';
import ShopImageUpload from './pages/ShopImageUpload';
import CouponList from './pages/CouponList';
import CouponNew from './pages/CouponNew';
import CouponEdit from './pages/CouponEdit';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';

import NoticeList from './pages/NoticeList';
import NoticeDetail from './pages/NoticeDetail';
import Faq from './pages/Faq';
import ShopNew from './pages/ShopNew';
import { ReactComponent as GoogleIcon } from './images/google-icon.svg';

import { auth, provider, db } from './firebase';
import { signInWithPopup } from 'firebase/auth';
import { collection, query, where, onSnapshot, setDoc, doc } from 'firebase/firestore';

import { CircularProgress, Button } from '@mui/material';

function App() {

  // ログイン状態を監視(useAuthState)
  const [user, initializing] = useAuthState(auth);
  const [subscriptions, setSubscriptions] = useState(null);
  const [loading, setLoading] = useState(false);
  let jsxToRender; 
  
  useEffect(() => {
    if (user) {
      setLoading(true);
      // ログインユーザーの決済情報を取得する
      const subscriptionsRef = collection(db, 'customers', user.uid, 'subscriptions');
      const q = query(subscriptionsRef, where('status', 'in', ['trialing', 'active']));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        if (snapshot.size === 0) {
          // window.location.href = 'https://buy.stripe.com/test_3cscQ58XBeKI7HW000'
          // 決済が有効でない場合
          setSubscriptions(false);
        } else {
          // 決済が有効な場合
          setSubscriptions(true);
        }
        setLoading(false);
      }, (error) => {
        // エラー時の処理
        console.error("Error fetching subscriptions: ", error);
        setLoading(false);
      });
      
      // クリーンナップ関数を設定しておくと、
      // 1.useEffectが次回実行されるタイミング
      // 2.このコンポーネントがアンマウントされるタイミング
      // で実行される
      return () => unsubscribe();
    }
    // } else if (!initializing) {
    //   setLoading(false);
    // }
  }, [user, initializing]);


  // if (initializing || subscriptions === null) {
  if (initializing || loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }
  
  if(user) {    

    if (subscriptions) {
      // 決済が有効な場合
      jsxToRender = (
        <div className="App">
          {/* TODO:ヘッダーをここに記述　→ textはstoreで管理して、各コンポーネントでsetしてハンドリング */}
          <Routes>
            <Route path="/" element={<Menu />}/>

            {/* menu */}
            <Route path="/pointReading" element={<PointReading user={user} />}/>
            <Route path="/cardIssuance" element={<CardIssuance user={user} />}/>
            <Route path="/shopInfo" element={<ShopInfo user={user} />}/>
            <Route path="/shopImageUpload" element={<ShopImageUpload user={user} />}/>
            <Route path="/couponList" element={<CouponList user={user} />}/>

            {/* appbar */}
            <Route path="/noticeList" element={<NoticeList />}/>

            {/* drawer */}
            <Route path="/faq" element={<Faq />}/>
            <Route path="/termsOfService" element={<TermsOfService />}/>
            <Route path="/privacyPolicy" element={<PrivacyPolicy />}/>

            {/* クーポン関連 */}
            <Route path="/couponNew" element={<CouponNew user={user} />}/>
            <Route path="/couponEdit" element={<CouponEdit user={user} />}/>

            {/* お知らせ関連 */}
            <Route path="/noticeDetail/:id" element={<NoticeDetail />}/>

            {/* ポイント関連 */}
            <Route path="/pointUsing" element={<PointUsing />}/>

          </Routes>
        </div>
      );
      
    } else {
      // 決済が有効でない場合
      jsxToRender = (
        <div className="App">
          {/* <Plan /> */}
          <Routes>
            <Route path="/" element={<ShopNew />}/>
            <Route path="/plan" element={<Plan />}/>
          </Routes>
        </div>
      );
    }

  } else {
    // ログインしていない場合
    jsxToRender = (
      <div className="App">
        <LogInButton />
      </div>
    );
  }

  return jsxToRender;

}

export default App;


// コンポーネント内で使用する関数

function LogInButton() {

  const signInWithGoogle = async () => {
    // signInWithPopup(auth, provider);
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Firestoreにユーザー情報を保存
      const userRef = doc(db, 'customers', user.uid);
      await setDoc(userRef, {
        displayName: user.displayName || '',
        email: user.email,
        // 他に保存したいユーザー情報があればここに追加
      }, { merge: true });

    } catch (error) {
      // サインインに失敗した場合のエラーハンドリング
      console.error('サインインエラー', error);
    }
  };

  return (
    // <button onClick={signInWithGoogle}>
    //   <p>Googleでログイン</p>
    // </button>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Button
      variant="outlined" // または 'outlined' など、他のスタイルを選択可能
      color="primary"
      // startIcon={<GoogleIcon />}
      startIcon={<GoogleIcon style={{ width: '20px', height: '20px' }} />}
      onClick={signInWithGoogle}
      style={{ textTransform: 'none' }} // ボタンのテキストを大文字に変換しない
      >
        Googleアカウントでログイン
      </Button>
   </div>
  );

}

// googleのユーザー情報を取得したい場合は、以下を使用
// function UseInfo() {
//   return (
//     <div>
//       <img src={auth.currentUser.photoURL} alt="" />
//     </div>
//   );
// }


